// Path: /src/components/EnhancedLeaderboard.js
// Updated with quick preview functionality for players

import React, { useState, useEffect } from 'react';
import LeaderboardTable from './LeaderboardTable';
import GolfersLeaderboard from './GolfersLeaderboard';
import CoursePage from './CoursePage';
import ScoringInfo from './ScoringInfo';
import { formatDate } from './utils';
import NoticeAlert from './NoticeAlert';
import SponsorBanner from './SponsorBanner'; // Add this import

const EnhancedLeaderboard = () => {
  // State variables
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [expandedPlayers, setExpandedPlayers] = useState({});
  const [previewPlayers, setPreviewPlayers] = useState({}); // New state for quick preview
  const [expandedGolfers, setExpandedGolfers] = useState({});
  const [sortConfig, setSortConfig] = useState({ key: 'rank', direction: 'asc' }); // Changed to rank as default
  const [golfersSortConfig, setGolfersSortConfig] = useState({ key: 'rank', direction: 'asc' }); // Changed to rank as default
  const [entrantSearchTerm, setEntrantSearchTerm] = useState(''); // Search for entrants tab
  const [golferSearchTerm, setGolferSearchTerm] = useState('');   // Search for golfers tab
  const [lastUpdated, setLastUpdated] = useState('Loading...');
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('leaderboard'); // 'leaderboard', 'golfers', or 'course'
  const [golfersData, setGolfersData] = useState([]);

  // Format date in short format: 24 Mar 9:51pm
  const formatShortDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12 AM
    
    return `${day} ${month} ${formattedHours}:${minutes}${ampm}`;
  };

  // Fetch leaderboard data
  const fetchLeaderboard = async () => {
    try {
      setIsLoading(true);
      
      // Add timestamp to prevent caching
      const timestamp = new Date().getTime();
      
      // Fetch entrants data
      const entrantsResponse = await fetch(`/api/leaderboard?t=${timestamp}`);
      if (!entrantsResponse.ok) {
        throw new Error('Failed to fetch leaderboard data');
      }
      const entrantsData = await entrantsResponse.json();
      setLeaderboardData(entrantsData);
      
      // Make leaderboard data globally accessible for components that need it
      window.leaderboardData = entrantsData;  // Use entrantsData directly, not leaderboardData state
      console.log('Leaderboard data made globally available for rankings');
      
      // Fetch all golfers data
      const golfersResponse = await fetch(`/api/golfers?t=${timestamp}`);
      if (!golfersResponse.ok) {
        throw new Error('Failed to fetch golfers data');
      }
      const rawGolfersData = await golfersResponse.json();
      
      // Process golfers data
      const processedGolfers = rawGolfersData.map(golfer => {
        // Find which entrants have this golfer
        const entrantsWithGolfer = entrantsData.filter(entrant => 
          entrant.golfers && entrant.golfers.some(g => g.name === golfer.Golfer)
        ).map(entrant => entrant.player);
        
        // Check if player has withdrawn
        const isWithdrawn = Boolean(golfer.D1WD || golfer.D2WD || golfer.D3WD || golfer.D4WD);
        
        // Check if the player is actively playing (has a CURRENT day and is on a hole)
        const isPlaying = 
          (golfer.D1Status === 'CURRENT' && parseInt(golfer.D1Hole || '0', 10) > 0 && golfer.D1HoleDisplay !== 'F') ||
          (golfer.D2Status === 'CURRENT' && parseInt(golfer.D2Hole || '0', 10) > 0 && golfer.D2HoleDisplay !== 'F') ||
          (golfer.D3Status === 'CURRENT' && parseInt(golfer.D3Hole || '0', 10) > 0 && golfer.D3HoleDisplay !== 'F') ||
          (golfer.D4Status === 'CURRENT' && parseInt(golfer.D4Hole || '0', 10) > 0 && golfer.D4HoleDisplay !== 'F');
        
        // Determine which day the tee time applies to
        // TeeTime is for the next round that hasn't started yet
        let teeTimeDay = null;
        
        // Logic to determine which day the tee time applies to
        if (golfer.D1Status === 'NEXT' || 
            (golfer.D1Status === 'CURRENT' && parseInt(golfer.D1Hole || '0', 10) === 0)) {
          teeTimeDay = 1;
        } else if (golfer.D2Status === 'NEXT' || 
                   (golfer.D2Status === 'CURRENT' && parseInt(golfer.D2Hole || '0', 10) === 0)) {
          teeTimeDay = 2;
        } else if (golfer.D3Status === 'NEXT' || 
                   (golfer.D3Status === 'CURRENT' && parseInt(golfer.D3Hole || '0', 10) === 0)) {
          teeTimeDay = 3;
        } else if (golfer.D4Status === 'NEXT' || 
                   (golfer.D4Status === 'CURRENT' && parseInt(golfer.D4Hole || '0', 10) === 0)) {
          teeTimeDay = 4;
        }
        
        // Return processed golfer with entrants list
        return {
          name: golfer.Golfer,
          handicap: parseInt(golfer.Handicap || '0', 10),
          country: golfer.Country || '',
          rank: parseInt(golfer.Rank || '0', 10),
          slug: golfer.Slug || '',
          image: golfer.Image || null,
          isCut: golfer.Cut === 'CUT',
          isWithdrawn: isWithdrawn,
          isPlaying: isPlaying, 
          schedule: golfer.Schedule || '',
          back9: golfer.Back9 === 'BACK9',
          golferStatus: golfer.GolferStatus || '',
          teeTimeDay: teeTimeDay, // Store which day the tee time applies to
          tournyPar: golfer.TournyPar || null,
          tournyRank: golfer.TournyRank || null,
          tournyStrokes: golfer.TournyStrokes || null,
          day1: {
            score: golfer.Day1 || '',
            hole: parseInt(golfer.D1Hole || '0', 10),
            holeDisplay: golfer.D1HoleDisplay || golfer.D1Hole || '0',
            status: golfer.D1Status || '',
            points: parseInt(golfer.D1Score || '0', 10) || 0,
            isWD: Boolean(golfer.D1WD),
            withdrawnAt: golfer.D1WD || null,
            teeTime: teeTimeDay === 1 ? golfer.TeeTime || '' : '',  // Only set tee time if it applies to day 1
            parForDay: golfer.D1Par || null,
            predictedHole: golfer.PredictedHole || null,
            parScore: golfer.D1ParScore || null
          },
          day2: {
            score: golfer.Day2 || '',
            hole: parseInt(golfer.D2Hole || '0', 10),
            holeDisplay: golfer.D2HoleDisplay || golfer.D2Hole || '0',
            status: golfer.D2Status || '',
            points: parseInt(golfer.D2Score || '0', 10) || 0,
            isWD: Boolean(golfer.D2WD),
            withdrawnAt: golfer.D2WD || null,
            teeTime: teeTimeDay === 2 ? golfer.TeeTime || '' : '',  // Only set tee time if it applies to day 2
            parForDay: golfer.D2Par || null,
            parScore: golfer.D2ParScore || null
          },
          day3: {
            score: golfer.Day3 || '',
            hole: parseInt(golfer.D3Hole || '0', 10),
            holeDisplay: golfer.D3HoleDisplay || golfer.D3Hole || '0',
            status: golfer.D3Status || '',
            points: parseInt(golfer.D3Score || '0', 10) || 0,
            isWD: Boolean(golfer.D3WD),
            withdrawnAt: golfer.D3WD || null,
            teeTime: teeTimeDay === 3 ? golfer.TeeTime || '' : '',  // Only set tee time if it applies to day 3
            parForDay: golfer.D3Par || null,
            parScore: golfer.D3ParScore || null
          },
          day4: {
            score: golfer.Day4 || '',
            hole: parseInt(golfer.D4Hole || '0', 10),
            holeDisplay: golfer.D4HoleDisplay || golfer.D4Hole || '0',
            status: golfer.D4Status || '',
            points: parseInt(golfer.D4Score || '0', 10) || 0,
            isWD: Boolean(golfer.D4WD),
            withdrawnAt: golfer.D4WD || null,
            teeTime: teeTimeDay === 4 ? golfer.TeeTime || '' : '',  // Only set tee time if it applies to day 4
            parForDay: golfer.D4Par || null,
            parScore: golfer.D4ParScore || null
          },
          total: parseInt(golfer.Total || '0', 10) || 0,
          TeeTime: golfer.TeeTime || '', // Ensure TeeTime is included at top level
          entrants: entrantsWithGolfer
        };
      });

      // Helper function to find tee time for days other than day 1
      // This is a placeholder - in a real implementation, you would need to get this data from your backend
      function findTeeTimeForDay(golfer, day) {
        // For day 1, use the main TeeTime field
        if (day === 1) return golfer.TeeTime || '';
        
        // For other days:
        
        // If the status is NEXT and there's no hole yet, we should have a tee time
        if (day === 2 && golfer.D2Status === 'NEXT' && parseInt(golfer.D2Hole || '0', 10) === 0) {
          // This is where you would return the actual tee time for day 2
          // Since it's not in your current data structure, you could use a placeholder or 
          // add a new field in your CSV like D2TeeTime
          return golfer.D2TeeTime || '';  // Replace with actual field if available
        }
        
        if (day === 3 && golfer.D3Status === 'NEXT' && parseInt(golfer.D3Hole || '0', 10) === 0) {
          return golfer.D3TeeTime || '';  // Replace with actual field if available
        }
        
        if (day === 4 && golfer.D4Status === 'NEXT' && parseInt(golfer.D4Hole || '0', 10) === 0) {
          return golfer.D4TeeTime || '';  // Replace with actual field if available
        }
        
        // Default - no tee time available
        return '';
      }
      
      setGolfersData(processedGolfers);
      
      // Fetch last updated time
      const lastUpdatedResponse = await fetch(`/api/last-updated?t=${timestamp}`);
      if (lastUpdatedResponse.ok) {
        const lastUpdated = await lastUpdatedResponse.text();
        setLastUpdated(formatShortDate(lastUpdated));
      } else {
        setLastUpdated(formatShortDate(new Date()));
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Toggle expanded state for a player in entrants leaderboard
  const togglePlayerExpanded = (playerId) => {
    // When fully expanding, close any preview
    if (!expandedPlayers[playerId]) {
      setPreviewPlayers(prev => ({
        ...prev,
        [playerId]: false
      }));
    }
    
    setExpandedPlayers(prev => ({
      ...prev,
      [playerId]: !prev[playerId]
    }));
  };
  
  // New function to toggle player preview
  const togglePlayerPreview = (playerId) => {
    // Don't show preview if already fully expanded
    if (expandedPlayers[playerId]) return;
    
    setPreviewPlayers(prev => ({
      ...prev,
      [playerId]: !prev[playerId]
    }));
  };
  
  // Toggle expanded state for a golfer in golfers leaderboard
  const toggleGolferExpanded = (golferName) => {
    setExpandedGolfers(prev => ({
      ...prev,
      [golferName]: !prev[golferName]
    }));
  };

  // Request sorting for entrants leaderboard
  const requestSort = (key) => {
    setSortConfig(prevConfig => {
      if (prevConfig.key === key) {
        return { key, direction: prevConfig.direction === 'asc' ? 'desc' : 'asc' };
      }
      return { key, direction: key === 'total' ? 'desc' : 'asc' };
    });
  };
  
  // Request sorting for golfers leaderboard
  const requestGolfersSort = (key) => {
    setGolfersSortConfig(prevConfig => {
      if (prevConfig.key === key) {
        return { key, direction: prevConfig.direction === 'asc' ? 'desc' : 'asc' };
      }
      return { key, direction: key === 'total' ? 'desc' : 'asc' };
    });
  };

  // Handle search input change - separate handlers for each tab
  const handleEntrantSearchChange = (e) => {
    setEntrantSearchTerm(e.target.value);
  };
  
  const handleGolferSearchChange = (e) => {
    setGolferSearchTerm(e.target.value);
  };

  // Initialize data on component mount
  useEffect(() => {
    fetchLeaderboard();
    
    // Set up auto-refresh interval (5 minutes)
    const interval = setInterval(fetchLeaderboard, 5 * 60 * 1000);
    
    // Clean up on unmount
    return () => clearInterval(interval);
  }, []);

  // Filter and sort data for display - Entrants Leaderboard
  const getDisplayData = () => {
    // First sort by total points to assign ranks
    const rankedData = [...leaderboardData].sort((a, b) => b.total - a.total);
    
    // Assign ranks
    const rankMap = new Map();
    let currentRank = 1;
    let prevTotal = null;
    
    rankedData.forEach((entrant, index) => {
      if (prevTotal !== null && entrant.total === prevTotal) {
        rankMap.set(entrant.player, currentRank);
      } else {
        currentRank = index + 1;
        rankMap.set(entrant.player, currentRank);
        prevTotal = entrant.total;
      }
    });
    
    // Filter by search term - only search entrant names
    let filteredData = rankedData;
    if (entrantSearchTerm) {
      const term = entrantSearchTerm.toLowerCase();
      filteredData = rankedData.filter(entrant => 
        entrant.player && entrant.player.toLowerCase().includes(term)
      );
    }
    
    // Apply sorting
    if (sortConfig.key !== 'rank') {
      filteredData.sort((a, b) => {
        let comparison = 0;
        
        if (sortConfig.key === 'player') {
          comparison = a.player.localeCompare(b.player);
        } else if (sortConfig.key === 'total') {
          comparison = a.total - b.total;
        }
        
        return sortConfig.direction === 'asc' ? comparison : -comparison;
      });
    }
    
    // Update golfers in each entrant with the latest data from golfersData
    const enrichedData = filteredData.map(entrant => {
      const golfers = entrant.golfers || [];
      const enrichedGolfers = golfers.map(golfer => {
        // Find the matching golfer in golfersData by name
        const fullGolferData = golfersData.find(g => g.name === golfer.name);
        
        // If found, use the full golfer data with entrant-specific properties preserved
        if (fullGolferData) {
          return {
            ...fullGolferData,
            group: golfer.group // Preserve the group number from the entrant data
          };
        }
        
        // If not found, return the original golfer data
        return golfer;
      });
      
      return {
        ...entrant,
        golfers: enrichedGolfers,
        rank: rankMap.get(entrant.player)
      };
    });
    
    return enrichedData;
  };

  // Filter and sort data for golfers leaderboard
  const getGolfersDisplayData = () => {
    // Sort golfers by total points
    const rankedGolfers = [...golfersData].sort((a, b) => b.total - a.total);
    
    // Assign ranks
    const rankMap = new Map();
    let currentRank = 1;
    let prevTotal = null;
    
    rankedGolfers.forEach((golfer, index) => {
      if (prevTotal !== null && golfer.total === prevTotal) {
        rankMap.set(golfer.name, currentRank);
      } else {
        currentRank = index + 1;
        rankMap.set(golfer.name, currentRank);
        prevTotal = golfer.total;
      }
    });
    
    // Filter by search term - only search golfer names and countries
    let filteredData = rankedGolfers;
    if (golferSearchTerm) {
      const term = golferSearchTerm.toLowerCase();
      filteredData = rankedGolfers.filter(golfer => 
        (golfer.name && golfer.name.toLowerCase().includes(term)) ||
        (golfer.country && golfer.country.toLowerCase().includes(term))
      );
    }
    
    // Apply sorting
    if (golfersSortConfig.key !== 'rank') {
      filteredData.sort((a, b) => {
        let comparison = 0;
        
        if (golfersSortConfig.key === 'name') {
          comparison = a.name.localeCompare(b.name);
        } else if (golfersSortConfig.key === 'total') {
          comparison = a.total - b.total;
        } else if (golfersSortConfig.key === 'handicap') {
          comparison = a.handicap - b.handicap;
        } else if (golfersSortConfig.key === 'country') {
          comparison = a.country.localeCompare(b.country);
        } else if (golfersSortConfig.key === 'entrants') {
          comparison = a.entrants.length - b.entrants.length;
        }
        
        return golfersSortConfig.direction === 'asc' ? comparison : -comparison;
      });
    }
    
    // Return data with rank
    return filteredData.map(golfer => ({
      ...golfer,
      rank: rankMap.get(golfer.name)
    }));
  };

  const displayData = getDisplayData();
  const golfersDisplayData = getGolfersDisplayData();
  
  // Get the current search term and handler based on active tab
  const currentSearchTerm = activeTab === 'leaderboard' ? entrantSearchTerm : golferSearchTerm;
  const handleSearchChange = activeTab === 'leaderboard' ? handleEntrantSearchChange : handleGolferSearchChange;

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Hero header with background image */}
      <header className="bg-gradient-to-r from-green-900 to-green-700 relative overflow-hidden">
        {/* Dark overlay with reduced opacity */}
        <div className="absolute inset-0 bg-black opacity-30 z-0"></div>
        <div 
          className="absolute inset-0 bg-center bg-cover opacity-30 z-0" 
          style={{backgroundImage: "url('/img/banner-augusta.jpg')"}}
        ></div>
        
        <div className="container mx-auto px-4 py-8 relative z-10">
          <div className="flex items-center justify-center">
            <div className="p-3 bg-white bg-opacity-20 rounded-full mr-4">
              <span className="text-4xl">🏌️</span>
            </div>
            <div>
              <h1 className="text-3xl font-bold text-white drop-shadow-md">Royal Curragh Golf Club</h1>
              <p className="text-white font-medium">Masters Sweepstakes 2025</p>
            </div>
          </div>
        </div>
      </header>
      
      {/* Subtle stats bar */}
      <div className="bg-white border-b border-gray-200 shadow-sm">
        <div className="container mx-auto px-4 max-w-4xl">
          <div className="grid grid-cols-3 divide-x divide-gray-200 text-center py-2">
            <div className="text-center px-4">
              <div className="text-xs uppercase tracking-wider text-gray-500">Updated</div>
              <div className="text-sm font-medium text-gray-700">{lastUpdated}</div>
            </div>
            <div className="text-center px-4">
              <div className="text-xs uppercase tracking-wider text-gray-500">Players</div>
              <div className="text-sm font-medium text-gray-700">{leaderboardData.length}</div>
            </div>
            <div className="text-center px-4">
              <div className="text-xs uppercase tracking-wider text-gray-500">Golfers</div>
              <div className="text-sm font-medium text-gray-700">{golfersData.length}</div>
            </div>
          </div>
        </div>
      </div>
      {/* Sponsor Banner - added here */}
      <SponsorBanner />
      {/* Main content area */}
      <div className="container mx-auto pt-2 pb-6 max-w-4xl">


        {/* Card with tabs */}
        <div className="bg-white rounded-xl shadow-lg overflow-hidden mb-8">
          {/* Tabs */}
          <div className="flex border-b border-gray-200 overflow-x-auto">
            <button
              className={`py-4 px-4 sm:px-6 text-sm font-medium focus:outline-none flex items-center flex-shrink-0 ${
                activeTab === 'leaderboard'
                  ? 'text-green-700 border-b-2 border-green-700'
                  : 'text-gray-500 hover:text-green-600'
              }`}
              onClick={() => setActiveTab('leaderboard')}
            >
              <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
              </svg>
              Players
            </button>
            <button
              className={`py-4 px-4 sm:px-6 text-sm font-medium focus:outline-none flex items-center flex-shrink-0 ${
                activeTab === 'golfers'
                  ? 'text-green-700 border-b-2 border-green-700'
                  : 'text-gray-500 hover:text-green-600'
              }`}
              onClick={() => setActiveTab('golfers')}
            >
              <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
              </svg>
              Golfers
            </button>
            <button
              className={`py-4 px-4 sm:px-6 text-sm font-medium focus:outline-none flex items-center flex-shrink-0 ${
                activeTab === 'course'
                  ? 'text-green-700 border-b-2 border-green-700'
                  : 'text-gray-500 hover:text-green-600'
              }`}
              onClick={() => setActiveTab('course')}
            >
              <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7" />
              </svg>
              Course
            </button>
          </div>
          
          {/* Search bar - only show for Players and Golfers tabs */}
          {activeTab !== 'course' && (
            <div className="p-4 bg-gray-50 border-b">
              <div className="relative">
                <input
                  type="text"
                  className="w-full pl-10 pr-4 py-2 rounded-lg bg-white border border-gray-300 focus:ring-2 focus:ring-green-500 focus:border-green-500 transition-all"
                  placeholder={`Search ${activeTab === 'leaderboard' ? 'players' : 'golfers'}...`}
                  value={currentSearchTerm}
                  onChange={handleSearchChange}
                />
                <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">🔍</span>
              </div>
            </div>
          )}
          
          {/* Content area - show different content based on active tab */}
          <div className="p-6">
            {isLoading ? (
              <div className="text-center py-8">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-green-700 mx-auto mb-4"></div>
                <p>Loading data...</p>
              </div>
            ) : activeTab === 'leaderboard' ? (
              displayData.length === 0 ? (
                <div className="text-center py-8">
                  <p>No matching players found</p>
                </div>
              ) : (
                <LeaderboardTable 
                  data={displayData} 
                  expandedPlayers={expandedPlayers}
                  togglePlayerExpanded={togglePlayerExpanded}
                  previewPlayers={previewPlayers}
                  togglePlayerPreview={togglePlayerPreview}
                  sortConfig={sortConfig}
                  requestSort={requestSort}
                />
              )
            ) : activeTab === 'golfers' ? (
              golfersDisplayData.length === 0 ? (
                <div className="text-center py-8">
                  <p>No matching golfers found</p>
                </div>
              ) : (
                <GolfersLeaderboard 
                  data={golfersDisplayData} 
                  expandedGolfers={expandedGolfers}
                  toggleGolferExpanded={toggleGolferExpanded}
                  sortConfig={golfersSortConfig}
                  requestSort={requestGolfersSort}
                  allEntrantsData={leaderboardData} // Pass the entire leaderboard data array
                />
              )
            ) : (
              <CoursePage />
            )}
          </div>
        </div>
        
        {/* Only show Scoring Info if not on Course tab */}
        {activeTab !== 'course' && (
          <div className="bg-white rounded-xl shadow-lg overflow-hidden mb-8">
            <div className="bg-gradient-to-r from-green-700 to-green-900 p-4 text-white">
              <h2 className="text-xl font-bold">Scoring Guide &amp; Legend</h2>
            </div>
            
            <div className="p-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <h3 className="text-lg font-semibold mb-2">Point Calculation</h3>
                  <p className="mb-3">
                    Points are earned when golfers finish under par. The total points are calculated by multiplying strokes under par by the golfer's handicap.
                  </p>
                  <div className="bg-gray-50 p-4 rounded-lg border-l-4 border-green-700 hover:bg-green-50 transition-colors">
                    <p className="font-bold mb-1">Example:</p>
                    <p>A golfer with a handicap of 15 who shoots 4 under par earns 60 points (4 × 15 = 60).</p>
                  </div>
                </div>
                
                <div>
                  <h3 className="text-lg font-semibold mb-2">Status Indicators</h3>
                  <div className="grid grid-cols-2 gap-2">
                    <div className="flex items-center p-2 rounded hover:bg-gray-50 transition-colors">
                      <span className="text-green-700 font-bold mr-2">🏁</span>
                      <span>Round completed</span>
                    </div>
                    <div className="flex items-center p-2 rounded hover:bg-gray-50 transition-colors">
                      <span className="text-yellow-500 font-bold mr-2">⛳</span>
                      <span>In progress</span>
                    </div>
                    <div className="flex items-center p-2 rounded hover:bg-gray-50 transition-colors">
                      <span className="text-gray-500 font-bold mr-2">🕒</span>
                      <span>Waiting to tee off</span>
                    </div>
                    <div className="flex items-center p-2 rounded hover:bg-gray-50 transition-colors">
                      <span className="text-red-500 font-bold mr-2">⊘</span>
                      <span>Cut</span>
                    </div>
                    <div className="flex items-center p-2 rounded hover:bg-gray-50 transition-colors">
                      <span className="text-green-700 font-bold mr-2">🩹</span>
                      <span>Withdrawn</span>
                    </div>
                  </div>
                  
                  <h3 className="text-lg font-semibold mt-4 mb-2">Points Legend</h3>
                  <div className="space-y-2">
                    <div className="flex items-center p-2 rounded hover:bg-gray-50 transition-colors">
                      <span className="w-4 h-4 rounded-full bg-yellow-300 mr-2 shadow-sm"></span>
                      <span>Exceptional performance</span>
                    </div>
                    <div className="flex items-center p-2 rounded hover:bg-gray-50 transition-colors">
                      <span className="w-4 h-4 rounded-full bg-green-100 mr-2"></span>
                      <span>Strong performance</span>
                    </div>
                    <div className="flex items-center p-2 rounded hover:bg-gray-50 transition-colors">
                      <span className="w-4 h-4 rounded-full bg-blue-100 mr-2"></span>
                      <span>Points earned</span>
                    </div>
                    <div className="flex items-center p-2 rounded hover:bg-gray-50 transition-colors">
                      <span className="w-4 h-4 rounded-full bg-gray-100 mr-2"></span>
                      <span>No points earned</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      
      <footer className="bg-green-900 text-white text-center p-4">
        <div className="container mx-auto">
          <p><span className="mr-2">©️</span> <a href="https://www.sweepstakes.ie" className="hover:underline" target="_blank" rel="noopener noreferrer">www.sweepstakes.ie</a> 2025</p>
        </div>
      </footer>
    </div>
  );
};

export default EnhancedLeaderboard;