// Path: /src/components/LeaderboardTable.js

import React from 'react';
import SharedGolferCard from './SharedGolferCard';
import { getCountryFlag } from './utils';
import QuickPreviewCard from './QuickPreviewCard'; 

const LeaderboardTable = ({ 
  data, 
  expandedPlayers, 
  togglePlayerExpanded, 
  previewPlayers,    
  togglePlayerPreview,  
  sortConfig, 
  requestSort 
}) => {
  // Function to determine the status icon for an entrant
  // Updated getEntrantStatusIcon function for LeaderboardTable.js
  const getEntrantStatusIcon = (entrant) => {
    // Filter out withdrawn golfers for status checks
    const activeGolfers = entrant.golfers.filter(golfer => 
      !golfer.isWithdrawn && !golfer.day1.isWD && !golfer.day1.withdrawnAt && 
      !golfer.day2.isWD && !golfer.day2.withdrawnAt && 
      !golfer.day3.isWD && !golfer.day3.withdrawnAt && 
      !golfer.day4.isWD && !golfer.day4.withdrawnAt && 
      !golfer.isCut
    );
    
    // If no active golfers (all withdrawn or cut), show finished icon
    if (activeGolfers.length === 0) {
      return <span className="mr-1 text-green-700" title="All golfers finished or withdrew">🏁</span>;
    }
    
    // HIGHEST PRIORITY: Check if any golfer is actively playing on the course
    const hasActiveGolfers = activeGolfers.some(golfer => {
      const isOnCourse = 
        (golfer.day1.status === 'CURRENT' && golfer.day1.hole > 0 && golfer.day1.hole < 19 && golfer.day1.holeDisplay !== 'F') ||
        (golfer.day2.status === 'CURRENT' && golfer.day2.hole > 0 && golfer.day2.hole < 19 && golfer.day2.holeDisplay !== 'F') ||
        (golfer.day3.status === 'CURRENT' && golfer.day3.hole > 0 && golfer.day3.hole < 19 && golfer.day3.holeDisplay !== 'F') ||
        (golfer.day4.status === 'CURRENT' && golfer.day4.hole > 0 && golfer.day4.hole < 19 && golfer.day4.holeDisplay !== 'F');
      
      return isOnCourse;
    });
    
    if (hasActiveGolfers) {
      return <span className="mr-1 text-yellow-500" title="Golfer currently playing">⛳</span>;
    }
    
    // SECOND PRIORITY: Check for upcoming tee times
    const hasUpcomingTeeTime = activeGolfers.some(golfer => 
      golfer.TeeTime && 
      golfer.TeeTime !== 'On Course' && 
      golfer.TeeTime !== ''
    );
    
    if (hasUpcomingTeeTime) {
      return <span className="mr-1 text-gray-500" title="Upcoming tee time">🕒</span>;
    }
    
    // THIRD PRIORITY: All golfers finished for the day but tournament not complete
    const allFinishedDay = activeGolfers.every(golfer => {
      // Check if the day they're playing is finished
      if (golfer.day1.status === 'CURRENT') {
        return golfer.day1.holeDisplay === 'F';
      } else if (golfer.day2.status === 'CURRENT') {
        return golfer.day2.holeDisplay === 'F';
      } else if (golfer.day3.status === 'CURRENT') {
        return golfer.day3.holeDisplay === 'F';
      } else if (golfer.day4.status === 'CURRENT') {
        return golfer.day4.holeDisplay === 'F';
      }
      return false;
    });
    
    // FINAL CHECK: All golfers finished the tournament completely
    const allFinishedTournament = activeGolfers.every(golfer => 
      golfer.golferStatus === 'FINISHED'
    );
    
    if (allFinishedDay || allFinishedTournament) {
      return <span className="mr-1 text-green-700" title="All golfers finished round">🏁</span>;
    }
    
    // Default - only happens if none of the above are true, should be rare
    return <span className="mr-1 text-gray-500" title="Waiting">⏱️</span>;
  };
  
  return (
    <div className="overflow-x-auto" style={{maxWidth: '100%'}}>
      <table className="min-w-full border-collapse leaderboard-table">
        <thead>
          <tr className="bg-gray-50 text-gray-500 text-xs uppercase tracking-wider">
            <th 
              className="px-2 py-2 text-left cursor-pointer hover:bg-gray-100 w-8"
              onClick={() => requestSort('rank')}
            >
              # {sortConfig.key === 'rank' && (
                <span>{sortConfig.direction === 'asc' ? '▲' : '▼'}</span>
              )}
            </th>
            <th 
              className="px-2 py-2 text-left cursor-pointer hover:bg-gray-100"
              onClick={() => requestSort('player')}
            >
              Player {sortConfig.key === 'player' && (
                <span>{sortConfig.direction === 'asc' ? '▲' : '▼'}</span>
              )}
            </th>
            <th 
              className="px-2 py-2 text-right cursor-pointer hover:bg-gray-100 w-16"
              onClick={() => requestSort('total')}
            >
              Pts {sortConfig.key === 'total' && (
                <span>{sortConfig.direction === 'asc' ? '▲' : '▼'}</span>
              )}
            </th>
            <th className="px-2 py-2 text-center w-10">Team</th>
          </tr>
        </thead>
        <tbody className="text-sm">
          {data.map((entrant) => (
            <React.Fragment key={entrant.player}>
              {/* Main row */}
              <tr className="border-b border-gray-200 hover:bg-gray-50 transition-colors">
                <td className="px-2 py-2 w-8">{entrant.rank}</td>
                <td 
                  className="px-2 py-2 cursor-pointer"
                  onClick={() => togglePlayerPreview(entrant.player)}
                >
                  <div className="flex items-center">
                    {getEntrantStatusIcon(entrant)}
                    <span className="truncate max-w-xs">{entrant.player}</span>
                  </div>
                </td>
                <td className="px-2 py-2 text-right font-bold text-green-700 w-16">{entrant.total}</td>
                <td className="px-2 py-2 text-center w-10">
                  <button
                    className="px-2 py-1 bg-green-700 text-white rounded-full hover:bg-green-800 transition-colors text-xs"
                    onClick={() => togglePlayerExpanded(entrant.player)}
                  >
                    {expandedPlayers[entrant.player] ? '▲' : '▼'}
                  </button>
                </td>
              </tr>
              
              {/* Quick Preview row - shown when player name is clicked */}
              {previewPlayers[entrant.player] && !expandedPlayers[entrant.player] && (
                <tr className="bg-gray-50">
                  <td colSpan={4} className="px-3 py-2">
                    <div className="animate-fadeIn w-full">
                      <QuickPreviewCard entrant={entrant} />
                    </div>
                  </td>
                </tr>
              )}
              
              {/* Expanded details */}
              {expandedPlayers[entrant.player] && (
                <tr className="bg-gray-50">
                  <td colSpan={4} className="px-3 py-3">
                    <div className="space-y-3 animate-fadeIn">
                      {/* Using SharedGolferCard with showEntrants=false */}
                      {entrant.golfers && entrant.golfers.map((golfer, index) => (
                        <SharedGolferCard key={index} golfer={golfer} showEntrants={false} />
                      ))}
                      
                      {/* Total points summary */}
                      <div className="flex justify-between items-center p-2 rounded-lg bg-gradient-to-r from-green-100 to-green-200 shadow-sm">
                        <span className="font-medium text-green-800">Total Points:</span>
                        <span className="text-lg font-bold text-green-800">{entrant.total}</span>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LeaderboardTable;