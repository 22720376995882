// Path: /src/components/SponsorBanner.js
import React from 'react';

const SponsorBanner = () => {
  return (
    <div 
      className="container mx-auto px-4 py-2 max-w-4xl text-center mb-2 mt-3"
      style={{ backgroundColor: '#305579' }}
    >
      <img 
        src="/img/sponsor.png" 
        alt="Sponsor" 
        className="mx-auto max-w-full"
        style={{ maxHeight: '60px' }} // Slightly reduced height
        onError={(e) => {
          console.error('Failed to load sponsor image');
          e.target.style.display = 'none';
        }}
      />
    </div>
  );
};

export default SponsorBanner;