// Path: /src/components/SharedGolferCard.js
// Updated to calculate rankings directly from entrants data

import React from 'react';
import RoundCard from './RoundCard';
import { getCountryFlag } from './utils';
import { SCORE_THRESHOLDS } from './constants';

const SharedGolferCard = ({ golfer, showEntrants = false, showTournamentStats = false }) => {
  // Optional list of entrants who have this golfer
  const entrants = golfer.entrants || [];
  
  // Check if this is a legendary score using the constant
  const isLegendaryScore = golfer.total >= SCORE_THRESHOLDS.LEGENDARY;
  
  // Function to determine CSS class for points display using constants
  const getPointsClass = (points) => {
    if (points >= SCORE_THRESHOLDS.LEGENDARY) return "legendary-score"; // Legendary
    if (points >= SCORE_THRESHOLDS.EXCEPTIONAL) return "gold-shine bg-yellow-300 text-green-900 border border-yellow-400"; // Exceptional
    if (points >= SCORE_THRESHOLDS.STRONG) return "bg-green-100 text-green-800 border border-green-400";    // Strong
    if (points > 0) return "bg-blue-100 text-blue-800 border border-blue-400";         // Points earned
    return "bg-gray-100 text-gray-600 border border-gray-300";                         // No points
  };
  
  return (
    <div className={`bg-white border border-gray-200 rounded-lg overflow-hidden shadow-sm ${isLegendaryScore ? 'legendary-gold-card' : ''}`}>
      {/* Golfer header */}
      <div className="flex justify-between items-center p-3 bg-gray-50 border-b golfer-header">
        <div className="flex items-center">
          {/* Show player image if available, otherwise use country flag */}
          {golfer.image ? (
            <img 
              src={golfer.image}
              alt={golfer.name}
              className="w-10 h-10 rounded-full object-cover mr-3 bg-gray-200"
              onError={(e) => {
                e.target.onerror = null; 
                e.target.src = ""; 
                e.target.className = "hidden"; 
              }}
            />
          ) : (
            <span className="text-xl mr-3" title={golfer.country}>
              {getCountryFlag(golfer.country)}
            </span>
          )}
          <div>
            <div className="font-medium flex items-center golfer-name">
              {golfer.rank > 0 && <span className="text-xs text-gray-500 mr-1">#{golfer.rank}</span>}
              {golfer.name}
              {/* Only show cut indicator if not withdrawn */}
              {!golfer.isWithdrawn && golfer.isCut && (
                <span className="ml-2 text-red-500" title="Cut">⊘</span>
              )}
            </div>
            <div className="flex items-center">
              <span className="text-sm text-gray-500">Handicap: {golfer.handicap}</span>
              {/* Always show country flag alongside handicap even if image exists */}
              <span className="text-sm ml-2" title={golfer.country}>
                {getCountryFlag(golfer.country)}
              </span>
            </div>
          </div>
        </div>
        <div>
          {/* Wrapper to ensure rainbow badges show correctly */}
          <div className="relative" style={{zIndex: 5}}>
            <span className={`inline-block px-3 py-1 rounded-full font-bold text-sm shadow-sm ${getPointsClass(golfer.total)}`}>
              {golfer.total}
            </span>
          </div>
        </div>
      </div>
      
      {/* Rounds grid - responsive layout: single row on desktop, 2x2 grid on mobile/tablet */}
      <div className="p-3">
        <div className="hidden md:flex space-x-2">
          {/* Desktop: All rounds in one row */}
          <div className="flex-1">
            <RoundCard day={golfer.day1} roundNumber={1} isCut={golfer.isCut} isBack9={golfer.back9} isLegendary={isLegendaryScore} />
          </div>
          <div className="flex-1">
            <RoundCard day={golfer.day2} roundNumber={2} isCut={golfer.isCut} isBack9={golfer.back9} isLegendary={isLegendaryScore} />
          </div>
          <div className="flex-1">
            <RoundCard day={golfer.day3} roundNumber={3} isCut={golfer.isCut} isBack9={golfer.back9} isLegendary={isLegendaryScore} />
          </div>
          <div className="flex-1">
            <RoundCard day={golfer.day4} roundNumber={4} isCut={golfer.isCut} isBack9={golfer.back9} isLegendary={isLegendaryScore} />
          </div>
        </div>
        
        {/* Mobile/Tablet: 2x2 grid */}
        <div className="grid grid-cols-2 gap-2 md:hidden">
          <div>
            <RoundCard day={golfer.day1} roundNumber={1} isCut={golfer.isCut} isBack9={golfer.back9} isLegendary={isLegendaryScore} />
          </div>
          <div>
            <RoundCard day={golfer.day2} roundNumber={2} isCut={golfer.isCut} isBack9={golfer.back9} isLegendary={isLegendaryScore} />
          </div>
          <div>
            <RoundCard day={golfer.day3} roundNumber={3} isCut={golfer.isCut} isBack9={golfer.back9} isLegendary={isLegendaryScore} />
          </div>
          <div>
            <RoundCard day={golfer.day4} roundNumber={4} isCut={golfer.isCut} isBack9={golfer.back9} isLegendary={isLegendaryScore} />
          </div>
        </div>
      </div>
      
      {/* Tournament stats section - only visible when showTournamentStats=true */}
      {showTournamentStats && (
        <div className="px-3 pb-3 border-t border-gray-200 pt-3 mt-2">
          <h3 className="font-medium text-gray-700 mb-2">Tournament Performance</h3>
          <div className="flex flex-wrap gap-2">
            <div className="flex items-center bg-gray-50 rounded-lg px-3 py-2 border border-gray-200">
              <span className="text-gray-500 mr-2">Rank:</span>
              <span className="font-bold text-gray-800">
                {golfer.tournyRank ? 
                  (golfer.tournyRank.toString().startsWith('T') ? golfer.tournyRank : `${golfer.tournyRank}${getOrdinalSuffix(golfer.tournyRank)}`) : 
                  'N/A'}
              </span>
            </div>
            
            <div className="flex items-center bg-gray-50 rounded-lg px-3 py-2 border border-gray-200">
              <span className="text-gray-500 mr-2">Strokes:</span>
              <span className="font-bold text-gray-800">
                {golfer.tournyStrokes || 'N/A'}
              </span>
            </div>
            
            <div className="flex items-center bg-gray-50 rounded-lg px-3 py-2 border border-gray-200">
              <span className="text-gray-500 mr-2">Total:</span>
              <span className={`font-bold ${
                golfer.tournyPar ? 'text-gray-800' : 'text-gray-500'
              }`}>
                {golfer.tournyPar || 'N/A'}
              </span>
            </div>
          </div>
        </div>
      )}
      
      {/* Conditional display of entrants - directly display with ranks */}
      {showEntrants && entrants.length > 0 && (
        <div className="px-3 pb-3 border-t border-gray-200 pt-3">
          <h3 className="font-medium text-gray-700 mb-2">Players with this golfer ({entrants.length}):</h3>
          <div className="max-h-40 overflow-y-auto p-2 bg-white rounded border border-gray-200">
            <ul className="divide-y divide-gray-100">
              {entrants.map((entrant, index) => {
                // Calculate position based on entrant name from the entrants array
                // We assume entrants contains player names that can be matched with team data
                let position = "";
                
                // If the entrant object has a position field, use it
                if (typeof entrant === 'object' && entrant.rank) {
                  position = entrant.rank;
                } else if (entrant && entrant._rank) {
                  position = entrant._rank;
                } else if (golfer._entrantsData) {
                  // If golfer has entrants data with rankings
                  const entrantObj = golfer._entrantsData.find(e => 
                    e.player === entrant || (typeof entrant === 'object' && e.player === entrant.name)
                  );
                  if (entrantObj && entrantObj.rank) {
                    position = entrantObj.rank;
                  }
                }
                
                const entrantName = typeof entrant === 'object' ? entrant.name || entrant.player : entrant;
                
                return (
                  <li key={index} className="py-1 px-2 hover:bg-gray-50 flex justify-between items-center">
                    <span className="truncate">{entrantName}</span>
                    {position && (
                      <span className="ml-2 text-xs px-2 py-1 bg-gray-100 rounded-full font-medium">
                        #{position}
                      </span>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

// Helper function to generate ordinal suffixes (1st, 2nd, 3rd, etc.)
const getOrdinalSuffix = (num) => {
  const parsedNum = parseInt(num, 10);
  if (isNaN(parsedNum)) return '';
  
  const j = parsedNum % 10;
  const k = parsedNum % 100;
  
  if (j === 1 && k !== 11) {
    return 'st';
  }
  if (j === 2 && k !== 12) {
    return 'nd';
  }
  if (j === 3 && k !== 13) {
    return 'rd';
  }
  return 'th';
};

// Export as default for direct import
export default SharedGolferCard;

// Also export with the GolferCard name to serve as a drop-in replacement
export const GolferCard = SharedGolferCard;