// Path: /src/components/GolfersLeaderboard.js
// Updated to pass entrant rankings to SharedGolferCard

import React from 'react';
import SharedGolferCard from './SharedGolferCard';
import { getCountryFlag } from './utils';
import { SCORE_THRESHOLDS } from './constants';

const GolfersLeaderboard = ({ 
  data, 
  expandedGolfers, 
  toggleGolferExpanded, 
  sortConfig, 
  requestSort,
  allEntrantsData // New prop for accessing all entrants data with rankings
}) => {
  // Function to determine the status icon for a golfer
  const getGolferStatusIcon = (golfer) => {
    // Check for withdrawal first - use bandage icon
    if (golfer.isWithdrawn || 
        golfer.day1.isWD || golfer.day1.withdrawnAt || 
        golfer.day2.isWD || golfer.day2.withdrawnAt || 
        golfer.day3.isWD || golfer.day3.withdrawnAt || 
        golfer.day4.isWD || golfer.day4.withdrawnAt) {
      return <span className="mr-1 text-gray-500" title="Withdrawn">🩹</span>;
    }
    
    // Check CUT status next
    if (golfer.isCut) {
      return <span className="mr-1 text-red-500" title="Cut">⊘</span>;
    }

    // Check GolferStatus - if finished overall
    if (golfer.golferStatus === 'FINISHED') {
      return <span className="mr-1 text-green-700" title="Tournament completed">🏁</span>;
    }

    // Check round status - which day is CURRENT
    let currentDay = null;
    let dayObj = null;
    
    if (golfer.day1.status === 'CURRENT') {
      currentDay = 1;
      dayObj = golfer.day1;
    } else if (golfer.day2.status === 'CURRENT') {
      currentDay = 2;
      dayObj = golfer.day2;
    } else if (golfer.day3.status === 'CURRENT') {
      currentDay = 3;
      dayObj = golfer.day3;
    } else if (golfer.day4.status === 'CURRENT') {
      currentDay = 4;
      dayObj = golfer.day4;
    }

    // If we found a CURRENT day
    if (currentDay && dayObj) {
      // Check if round is finished
      if (dayObj.holeDisplay === 'F' || dayObj.status === 'FINISHED') {
        return <span className="mr-1 text-green-700" title={`Round ${currentDay} completed`}>🏁</span>;
      }
      
      // Check if playing a hole
      if (dayObj.hole > 0 && dayObj.hole < 19 && dayObj.holeDisplay !== 'F') {
        return <span className="mr-1 text-yellow-500" title={`Playing round ${currentDay}, hole ${dayObj.hole}`}>⛳</span>;
      }
    }

    // Check for tee time - use the TeeTime field from golfer data
    if (golfer.TeeTime && golfer.TeeTime !== 'On Course') {
      return <span className="mr-1 text-gray-500" title={`Tee time: ${golfer.TeeTime}`}>🕒</span>;
    }

    // Check round statuses for NEXT
    if (golfer.day1.status === 'NEXT' || 
        golfer.day2.status === 'NEXT' ||
        golfer.day3.status === 'NEXT' ||
        golfer.day4.status === 'NEXT') {
      return <span className="mr-1 text-blue-500" title="Next round">⏭️</span>;
    }

    // Default - waiting
    return <span className="mr-1 text-gray-500" title="Waiting">⏱️</span>;
  };
  
  // Function to get CSS class for total points using constants
  const getPointsClass = (points) => {
    if (points >= SCORE_THRESHOLDS.LEGENDARY) return "legendary-score"; // Legendary
    if (points >= SCORE_THRESHOLDS.EXCEPTIONAL) return "gold-shine bg-yellow-300 text-green-900 border border-yellow-400"; // Exceptional
    if (points >= SCORE_THRESHOLDS.STRONG) return "bg-green-100 text-green-800 border border-green-400";    // Strong
    if (points > 0) return "bg-blue-100 text-blue-800 border border-blue-400";         // Points earned
    return "bg-gray-100 text-gray-600 border border-gray-300";                         // No points
  };
  
  // Create a mapping of entrant names to their ranks
  const createEntrantsRankMap = () => {
    if (!allEntrantsData || !Array.isArray(allEntrantsData)) return {};
    
    const rankMap = {};
    allEntrantsData.forEach(entrant => {
      if (entrant.player && entrant.rank) {
        rankMap[entrant.player] = entrant.rank;
      }
    });
    
    return rankMap;
  };
  
  // Generate the entrants rank map once
  const entrantsRankMap = createEntrantsRankMap();
  
  return (
    <div className="overflow-x-auto" style={{maxWidth: '100%'}}>
      <table className="min-w-full border-collapse leaderboard-table">
        <thead>
          <tr className="bg-gray-50 text-gray-500 text-xs uppercase tracking-wider">
            <th 
              className="px-2 py-2 text-left cursor-pointer hover:bg-gray-100 w-8"
              onClick={() => requestSort('rank')}
            >
              # {sortConfig.key === 'rank' && (
                <span>{sortConfig.direction === 'asc' ? '▲' : '▼'}</span>
              )}
            </th>
            <th 
              className="px-2 py-2 text-left cursor-pointer hover:bg-gray-100"
              onClick={() => requestSort('name')}
            >
              Golfer {sortConfig.key === 'name' && (
                <span>{sortConfig.direction === 'asc' ? '▲' : '▼'}</span>
              )}
            </th>
            <th 
              className="px-2 py-2 text-center cursor-pointer hover:bg-gray-100 hidden md:table-cell w-12"
              onClick={() => requestSort('handicap')}
            >
              Hcp {sortConfig.key === 'handicap' && (
                <span>{sortConfig.direction === 'asc' ? '▲' : '▼'}</span>
              )}
            </th>
            <th 
              className="px-2 py-2 text-center cursor-pointer hover:bg-gray-100 hidden md:table-cell w-12"
              onClick={() => requestSort('country')}
            >
              {sortConfig.key === 'country' && (
                <span>{sortConfig.direction === 'asc' ? '▲' : '▼'}</span>
              )}
            </th>
            <th 
              className="px-2 py-2 text-right cursor-pointer hover:bg-gray-100 w-14"
              onClick={() => requestSort('total')}
            >
              Pts {sortConfig.key === 'total' && (
                <span>{sortConfig.direction === 'asc' ? '▲' : '▼'}</span>
              )}
            </th>
            <th 
              className="px-2 py-2 text-right cursor-pointer hover:bg-gray-100 hidden md:table-cell w-14"
              onClick={() => requestSort('entrants')}
            >
              Plyrs {sortConfig.key === 'entrants' && (
                <span>{sortConfig.direction === 'asc' ? '▲' : '▼'}</span>
              )}
            </th>
            <th className="px-2 py-2 text-center w-10">Info</th>
          </tr>
        </thead>
        <tbody className="text-sm">
          {data.map((golfer) => (
            <React.Fragment key={golfer.name}>
              {/* Main row */}
              <tr className="border-b border-gray-200 hover:bg-gray-50 transition-colors">
                <td className="px-2 py-2 w-8">{golfer.rank}</td>
                <td 
                  className="px-2 py-2 cursor-pointer hover:text-green-700"
                  onClick={() => toggleGolferExpanded(golfer.name)}
                >
                  <div className="flex items-center">
                    {getGolferStatusIcon(golfer)}
                    <span className="truncate max-w-xs">{golfer.name}</span>
                  </div>
                </td>
                <td className="px-2 py-2 text-center hidden md:table-cell w-12">{golfer.handicap}</td>
                <td className="px-2 py-2 text-center hidden md:table-cell w-12">
                  <span title={golfer.country}>{getCountryFlag(golfer.country)}</span>
                </td>
                <td className="px-2 py-2 text-right w-14">
                  <div className="relative" style={{zIndex: 5}}>
                    <span className={`font-bold text-xs px-2 py-1 rounded-full shadow-sm inline-block ${getPointsClass(golfer.total)}`}>
                      {golfer.total}
                    </span>
                  </div>
                </td>
                <td className="px-2 py-2 text-right hidden md:table-cell w-14">
                  {golfer.entrants ? golfer.entrants.length : 0}
                </td>
                <td className="px-2 py-2 text-center w-10">
                  <button
                    className="px-2 py-1 bg-green-700 text-white rounded-full hover:bg-green-800 transition-colors text-xs"
                    onClick={() => toggleGolferExpanded(golfer.name)}
                  >
                    {expandedGolfers[golfer.name] ? '▲' : '▼'}
                  </button>
                </td>
              </tr>
              
              {/* Expanded details */}
              {expandedGolfers[golfer.name] && (
                <tr className="bg-gray-50">
                  <td colSpan={7} className="px-3 py-3">
                    <div className="space-y-3 animate-fadeIn">
                      {/* Using SharedGolferCard with showEntrants=true and showTournamentStats=true */}
                      <SharedGolferCard 
                        golfer={golfer} 
                        showEntrants={true} 
                        showTournamentStats={true}
                        entrantsRankMap={entrantsRankMap}
                      />
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default GolfersLeaderboard;