// Path: /src/components/QuickPreviewCard.js
import React, { useState } from 'react';
import { getCountryFlag } from './utils';
import { SCORE_THRESHOLDS } from './constants';
import RoundCard from './RoundCard';

const QuickPreviewCard = ({ entrant }) => {
  // State to track which golfers are expanded
  const [expandedGolfers, setExpandedGolfers] = useState({});
  
  // Toggle expanded state for a golfer
  const toggleGolferExpanded = (golferName) => {
    setExpandedGolfers(prev => ({
      ...prev,
      [golferName]: !prev[golferName]
    }));
  };
  
  // Function to get CSS class for points
  const getPointsClass = (points) => {
    if (points >= SCORE_THRESHOLDS.LEGENDARY) return "legendary-score"; // Legendary
    if (points >= SCORE_THRESHOLDS.EXCEPTIONAL) return "gold-shine bg-yellow-300 text-green-900"; // Exceptional
    if (points >= SCORE_THRESHOLDS.STRONG) return "bg-green-100 text-green-800";    // Strong
    if (points > 0) return "bg-blue-100 text-blue-800";         // Points earned
    return "bg-gray-100 text-gray-600";                         // No points
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-3 text-sm w-full">
      <div className="overflow-hidden"> 
        <div className="flex flex-wrap -mx-2">
          {entrant.golfers && entrant.golfers.map((golfer, index) => {
            // Determine if golfer is currently playing and which hole
            const currentPlayingInfo = getCurrentHoleInfo(golfer);
            const isExpanded = expandedGolfers[golfer.name] || false;
            const isLegendary = golfer.total >= SCORE_THRESHOLDS.LEGENDARY;
            const isGolferFinished = checkIfGolferFinished(golfer);
            
            return (
              <div key={index} className="w-full sm:w-1/2 lg:w-1/3 px-2 mb-2">
                {/* Golfer header - always visible */}
                <div 
                  className={`${isLegendary ? 'legendary-gold-card' : 'bg-gray-50'} ${isExpanded ? 'rounded-t' : 'rounded'} border border-gray-200 p-2 flex items-center justify-between cursor-pointer hover:bg-gray-100`}
                  onClick={() => toggleGolferExpanded(golfer.name)}
                >
                  {/* Left section with image and name */}
                  <div className="flex items-center flex-1 min-w-0" style={{background: 'transparent'}}>
                    {/* Show golfer image if available, otherwise show country flag */}
                    {golfer.image ? (
                      <img 
                        src={golfer.image}
                        alt={golfer.name}
                        className="w-6 h-6 rounded-full mr-2 object-cover flex-shrink-0"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "";
                          e.target.className = "hidden";
                        }}
                      />
                    ) : (
                      <span className="text-lg mr-2 flex-shrink-0" title={golfer.country}>
                        {getCountryFlag(golfer.country)}
                      </span>
                    )}
                    
                    {/* Name directly without nested divs */}
                    <span className="font-medium text-gray-800 truncate flex-1 mr-2" style={{background: 'transparent'}}>
                      {golfer.name}
                    </span>
                    
                    {/* Status indicators as siblings */}
                    {currentPlayingInfo && (
                      <span className="text-yellow-500 mr-1 flex-shrink-0" title="Currently playing">
                        ⛳
                      </span>
                    )}
                    
                    {isGolferFinished && !currentPlayingInfo && (
                      <span className="text-green-700 mr-1 flex-shrink-0" title="Finished round">
                        🏁
                      </span>
                    )}
                    
                    {golfer.isCut && (
                      <span className="text-red-500 mr-1 flex-shrink-0" title="Cut">
                        ⊘
                      </span>
                    )}
                    
                    {golfer.isWithdrawn && (
                      <span className="text-gray-500 mr-1 flex-shrink-0" title="Withdrawn">
                        🩹
                      </span>
                    )}
                  </div>
                  
                  {/* Points badge */}
                  <div className="flex-shrink-0">
                    {golfer.total > 0 ? (
                      <span className={`px-2 py-0.5 text-xs rounded-full ${getPointsClass(golfer.total)}`}>
                        {golfer.total}
                      </span>
                    ) : (
                      <span className="text-gray-400 text-xs">0</span>
                    )}
                  </div>
                </div>
                
                {/* Expanded section - includes handicap and round cards */}
                {isExpanded && (
                  <div className={`${isLegendary ? 'legendary-gold-card' : 'bg-white'} border-b border-l border-r border-gray-200 rounded-b p-2 animate-fadeIn`}>
                    {/* Handicap info - only visible when expanded */}
                    <div className="pb-2 mb-2 border-b border-gray-200">
                      <div className="flex items-center text-xs">
                        <span className="text-gray-500 mr-2">Handicap:</span>
                        <span className="font-medium">{golfer.handicap}</span>
                        <span className="mx-2 text-gray-300">|</span>
                        <span className="font-medium flex items-center">
                          <span className="mr-1">{getCountryFlag(golfer.country)}</span>
                          {golfer.country}
                        </span>
                      </div>
                    </div>
                    
                    {/* Round cards */}
                    <div className="grid grid-cols-2 gap-2">
                      <div>
                        <RoundCard day={golfer.day1} roundNumber={1} isCut={golfer.isCut} isBack9={golfer.back9} isLegendary={isLegendary} />
                      </div>
                      <div>
                        <RoundCard day={golfer.day2} roundNumber={2} isCut={golfer.isCut} isBack9={golfer.back9} isLegendary={isLegendary} />
                      </div>
                      <div>
                        <RoundCard day={golfer.day3} roundNumber={3} isCut={golfer.isCut} isBack9={golfer.back9} isLegendary={isLegendary} />
                      </div>
                      <div>
                        <RoundCard day={golfer.day4} roundNumber={4} isCut={golfer.isCut} isBack9={golfer.back9} isLegendary={isLegendary} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

// Helper function to get current hole info if golfer is playing
const getCurrentHoleInfo = (golfer) => {
  // Check all days to find which one is current and which hole they're on
  if (golfer.day1.status === 'CURRENT' && golfer.day1.hole > 0 && golfer.day1.hole < 19 && golfer.day1.holeDisplay !== 'F') {
    return { day: 1, hole: golfer.day1.holeDisplay || golfer.day1.hole };
  }
  if (golfer.day2.status === 'CURRENT' && golfer.day2.hole > 0 && golfer.day2.hole < 19 && golfer.day2.holeDisplay !== 'F') {
    return { day: 2, hole: golfer.day2.holeDisplay || golfer.day2.hole };
  }
  if (golfer.day3.status === 'CURRENT' && golfer.day3.hole > 0 && golfer.day3.hole < 19 && golfer.day3.holeDisplay !== 'F') {
    return { day: 3, hole: golfer.day3.holeDisplay || golfer.day3.hole };
  }
  if (golfer.day4.status === 'CURRENT' && golfer.day4.hole > 0 && golfer.day4.hole < 19 && golfer.day4.holeDisplay !== 'F') {
    return { day: 4, hole: golfer.day4.holeDisplay || golfer.day4.hole };
  }
  
  return null; // Not currently playing
};

// New helper function to check if golfer has finished their round
const checkIfGolferFinished = (golfer) => {
  // Check if the golfer has finished their round for the day
  return (golfer.day1.status === 'CURRENT' && golfer.day1.holeDisplay === 'F') ||
         (golfer.day2.status === 'CURRENT' && golfer.day2.holeDisplay === 'F') ||
         (golfer.day3.status === 'CURRENT' && golfer.day3.holeDisplay === 'F') ||
         (golfer.day4.status === 'CURRENT' && golfer.day4.holeDisplay === 'F') ||
         golfer.golferStatus === 'FINISHED';
};

export default QuickPreviewCard;