// Path: /src/components/CoursePage.js
import React, { useState, useEffect } from 'react';
import { getCountryFlag } from './utils';

const CoursePage = () => {
  const [currentHole, setCurrentHole] = useState(1);
  const [holesData, setHolesData] = useState([]);
  const [courseInfo, setCourseInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [golfersOnHole, setGolfersOnHole] = useState([]);
  const [golfersData, setGolfersData] = useState([]);
  const [entrantsData, setEntrantsData] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [error, setError] = useState(null);

  // Fetch all golfers and entrants data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const timestamp = new Date().getTime();
        
        // Fetch course data
        try {
          const courseResponse = await fetch(`/data/courses.json?t=${timestamp}`);
          const coursesData = await courseResponse.json();
          
          // Find the current tournament course - default to the first one if not specified
          const currentCourse = coursesData.courses[0];
          setCourseInfo(currentCourse);
          
          console.log(`Loaded course data for ${currentCourse.name}`);
        } catch (courseError) {
          console.error('Error loading course data:', courseError);
          // Set default course info as fallback
          setCourseInfo({
            name: "Memorial Park Golf Course",
            location: "Houston, Texas",
            tournament: "Houston Open 2025",
            holes: []
          });
        }
        
        // Fetch golfers data
        console.log('Fetching golfers data...');
        const golfersResponse = await fetch(`/api/golfers?t=${timestamp}`);
        if (!golfersResponse.ok) {
          throw new Error(`Failed to fetch golfers data: ${golfersResponse.status} ${golfersResponse.statusText}`);
        }
        const golfersData = await golfersResponse.json();
        console.log(`Fetched ${golfersData?.length || 0} golfers`);
        
        // Check if we have valid golfers data
        if (!golfersData || !Array.isArray(golfersData)) {
          throw new Error('Invalid golfers data: not an array');
        }
        
        setGolfersData(golfersData);
        
        // Fetch entrants data
        console.log('Fetching leaderboard data...');
        const entrantsResponse = await fetch(`/api/leaderboard?t=${timestamp}`);
        if (!entrantsResponse.ok) {
          throw new Error(`Failed to fetch leaderboard data: ${entrantsResponse.status} ${entrantsResponse.statusText}`);
        }
        const entrantsData = await entrantsResponse.json();
        console.log(`Fetched ${entrantsData?.length || 0} entrants`);
        
        // Check if we have valid entrants data
        if (!entrantsData || !Array.isArray(entrantsData)) {
          throw new Error('Invalid entrants data: not an array');
        }
        
        setEntrantsData(entrantsData);
        
        // Initialize hole data once we have all the data
        console.log('Initializing hole data...');
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message);
        // Initialize with empty data if fetch fails
        setLoading(false);
      }
    };

    fetchData();

    // Set up an interval to update the current time every minute
    const timeInterval = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(timeInterval);
  }, []);

  // Update hole data when course info changes
  useEffect(() => {
    if (courseInfo && courseInfo.holes && courseInfo.holes.length > 0) {
      initializeHoleData(golfersData, entrantsData, courseInfo.holes);
    }
  }, [courseInfo, golfersData, entrantsData]);

  // Update golfers on current hole when hole selection changes or time updates
  useEffect(() => {
    updateGolfersOnCurrentHole();
  }, [currentHole, holesData, currentTime]);

  // Initialize hole data using course info from JSON
  const initializeHoleData = (golfersData, entrantsData, courseHoles) => {
    try {
      // Calculate cumulative par for each hole
      let cumulativePar = 0;
      const holesWithPar = courseHoles.map(hole => {
        cumulativePar += hole.par;
        return {
          ...hole,
          cumulativePar
        };
      });

      // Find active golfers on each hole
      const holesWithActiveGolfers = holesWithPar.map(hole => {
        const activeGolfers = findGolfersOnHole(golfersData, hole.number, entrantsData);
        return {
          ...hole,
          activeGolfers
        };
      });

      setHolesData(holesWithActiveGolfers);
    } catch (error) {
      console.error('Error initializing hole data:', error);
      setError('Failed to initialize hole data');
      setHolesData([]);
    }
  };

  // Helper function to convert tee time string to Date object
  const parseTeeTime = (teeTimeStr) => {
    try {
      if (!teeTimeStr) return null;
      if (teeTimeStr === "On Course") return new Date(); // Return current time for "On Course"
      
      // Example format: "09:58 pm"
      const [timeStr, ampm] = teeTimeStr.split(' ');
      if (!timeStr) return null;
      
      const [hoursStr, minutesStr] = timeStr.split(':');
      if (!hoursStr || !minutesStr) return null;
      
      let hours = parseInt(hoursStr, 10);
      const minutes = parseInt(minutesStr, 10);
      
      // Invalid time components
      if (isNaN(hours) || isNaN(minutes)) return null;
      
      // Convert to 24-hour format
      if (ampm && ampm.toLowerCase() === 'pm' && hours < 12) hours += 12;
      if (ampm && ampm.toLowerCase() === 'am' && hours === 12) hours = 0;
      
      // Create date for today with the tee time (in GMT+0)
      const teeTime = new Date();
      teeTime.setUTCHours(hours, minutes, 0, 0);
      
      return teeTime;
    } catch (error) {
      console.error('Error parsing tee time:', error);
      return null;
    }
  };

  // Find golfers currently playing a specific hole with enhanced information
  // Find golfers currently playing a specific hole with enhanced information
  // Find golfers currently playing a specific hole with enhanced information
  // Find golfers currently playing a specific hole with enhanced information
  const findGolfersOnHole = (golfers, holeNumber, entrantsData) => {
    try {
      if (!golfers || !Array.isArray(golfers) || golfers.length === 0) return [];
      if (!entrantsData || !Array.isArray(entrantsData)) return [];
      
      // Get current time for tee time comparison
      const now = currentTime;
      
      // Convert DB golfers to the format we need
      const activeGolfers = [];
      
      golfers.forEach(golfer => {
        // Skip undefined or invalid golfers
        if (!golfer) return;
        
        const golferName = golfer.Golfer;
        // Skip if no golfer name
        if (!golferName) return;
        
        // Check if the golfer has withdrawn or been cut
        const isWithdrawn = Boolean(golfer.D1WD || golfer.D2WD || golfer.D3WD || golfer.D4WD);
        const isCut = golfer.Cut === 'CUT';
        
        // If the golfer has withdrawn or been cut, they won't be on the course
        if (isWithdrawn || isCut) return;
        
        const isBack9 = golfer.Back9 === 'BACK9';
        
        // Parse the golfer's tee time
        const teeTime = parseTeeTime(golfer.TeeTime);
        const hasTeeTimePassed = teeTime && now > teeTime;
        
        // Check if any round has status CURRENT (which means this is the day they're playing)
        const isDay1Current = golfer.D1Status === 'CURRENT';
        const isDay2Current = golfer.D2Status === 'CURRENT';
        const isDay3Current = golfer.D3Status === 'CURRENT';
        const isDay4Current = golfer.D4Status === 'CURRENT';
        
        // Check if round is finished with D1HoleDisplay="F"
        const day1Finished = golfer.D1HoleDisplay === 'F';
        const day2Finished = golfer.D2HoleDisplay === 'F';
        const day3Finished = golfer.D3HoleDisplay === 'F';
        const day4Finished = golfer.D4HoleDisplay === 'F';
        
        // Get current hole info - safely parse integers
        let day1Hole = parseInt(golfer.D1Hole || '0', 10) || 0;
        let day2Hole = parseInt(golfer.D2Hole || '0', 10) || 0;
        let day3Hole = parseInt(golfer.D3Hole || '0', 10) || 0;
        let day4Hole = parseInt(golfer.D4Hole || '0', 10) || 0;
        
        // Player with tee time that has passed but hasn't started yet
        if (hasTeeTimePassed && golfer.TeeTime && (
            (isDay1Current && day1Hole === 0) ||
            (isDay2Current && day2Hole === 0) ||
            (isDay3Current && day3Hole === 0) ||
            (isDay4Current && day4Hole === 0)
        )) {
          // If they have a tee time, make them hole 10
          if (holeNumber === 10) {
            activeGolfers.push({
              name: golferName, 
              round: isDay1Current ? 1 : isDay2Current ? 2 : isDay3Current ? 3 : 4,
              status: 'STARTING',
              player: getEntrantsWithGolfer(entrantsData, golferName),
              country: golfer.Country || 'Unknown',
              handicap: parseInt(golfer.Handicap || '0', 10) || 0,
              score: isDay1Current ? golfer.Day1 : isDay2Current ? golfer.Day2 : isDay3Current ? golfer.Day3 : golfer.Day4 || '',
              image: golfer.Image || null,
              slug: golfer.Slug || '',
              total: parseInt(golfer.Total || '0', 10) || 0,
              isBack9: isBack9,
              D1Hole: 0
            });
          }
          return; // No need to check further
        }
        
        // Check if the golfer is on the current hole we're looking at
        let onCurrentHole = false;
        let roundNumber = null;
        let dayScore = '';
        let parScore = '0';
        let currentHole = null;
        
        if (isDay1Current && !day1Finished) {
          // Get the hole and add 1 (as per your instructions)
          currentHole = day1Hole + 1;
          
          // If they are on hole 18, put them at hole 1
          if (isBack9 && currentHole === 19) {
            currentHole = 1;
          }
          
          if (currentHole === holeNumber) {
            onCurrentHole = true;
            roundNumber = 1;
            dayScore = golfer.Day1 || '';
            parScore = golfer.D1ParScore || '0';
          }
        } else if (isDay2Current && !day2Finished) {
          currentHole = day2Hole + 1;
          
          if (isBack9 && currentHole === 19) {
            currentHole = 1;
          }
          
          if (currentHole === holeNumber) {
            onCurrentHole = true;
            roundNumber = 2;
            dayScore = golfer.Day2 || '';
            parScore = golfer.D2ParScore || '0';
          }
        } else if (isDay3Current && !day3Finished) {
          currentHole = day3Hole + 1;
          
          if (isBack9 && currentHole === 19) {
            currentHole = 1;
          }
          
          if (currentHole === holeNumber) {
            onCurrentHole = true;
            roundNumber = 3;
            dayScore = golfer.Day3 || '';
            parScore = golfer.D3ParScore || '0';
          }
        } else if (isDay4Current && !day4Finished) {
          currentHole = day4Hole + 1;
          
          if (isBack9 && currentHole === 19) {
            currentHole = 1;
          }
          
          if (currentHole === holeNumber) {
            onCurrentHole = true;
            roundNumber = 4;
            dayScore = golfer.Day4 || '';
            parScore = golfer.D4ParScore || '0';
          }
        }
        
        if (onCurrentHole) {
          activeGolfers.push({
            name: golferName, 
            round: roundNumber,
            status: 'PLAYING',
            player: getEntrantsWithGolfer(entrantsData, golferName),
            country: golfer.Country || 'Unknown',
            handicap: parseInt(golfer.Handicap || '0', 10) || 0,
            score: dayScore,
            image: golfer.Image || null,
            slug: golfer.Slug || '',
            total: parseInt(golfer.Total || '0', 10) || 0,
            isBack9: isBack9,
            D1Hole: day1Hole,
            parScore: parScore
          });
        }
      });
      
      return activeGolfers;
    } catch (error) {
      console.error('Error finding golfers on hole:', error);
      return [];
    }
  };

  // Helper function to get comma-separated list of entrants with this golfer
  const getEntrantsWithGolfer = (entrantsData, golferName) => {
    try {
      if (!entrantsData || !Array.isArray(entrantsData) || !golferName) {
        return 'No players';
      }
      
      const entrantsWithGolfer = entrantsData.filter(entrant => 
        entrant && entrant.golfers && Array.isArray(entrant.golfers) && 
        entrant.golfers.some(g => g && g.name === golferName)
      ).map(entrant => entrant.player || 'Unknown');
      
      return entrantsWithGolfer.length > 0 ? entrantsWithGolfer.join(', ') : 'No players';
    } catch (error) {
      console.error('Error getting entrants with golfer:', error);
      return 'No players';
    }
  };

  const updateGolfersOnCurrentHole = () => {
    try {
      const selectedHole = holesData.find(hole => hole && hole.number === currentHole);
      if (selectedHole) {
        setGolfersOnHole(selectedHole.activeGolfers || []);
      } else {
        setGolfersOnHole([]);
      }
    } catch (error) {
      console.error('Error updating golfers on current hole:', error);
      setGolfersOnHole([]);
    }
  };

  const selectedHole = holesData.find(hole => hole && hole.number === currentHole) || 
                      (holesData && holesData.length > 0 ? holesData[0] : null);

  return (
    <div className="px-1 py-2 max-w-full">
      {/* Course Header */}
      <div className="bg-white rounded-xl shadow-md overflow-hidden mb-4">
        <div className="bg-gradient-to-r from-green-800 to-green-700 p-3 text-white">
          <h1 className="text-xl font-bold">{courseInfo?.name || "Loading course..."}</h1>
          <p className="text-sm">{courseInfo?.tournament || ""}</p>
        </div>

        {/* Hole Selector Grid */}
        <div className="p-3">
          <div className="grid grid-cols-3 sm:grid-cols-6 md:grid-cols-9 gap-2 max-w-full">
            {holesData.map((hole) => (
              <div key={hole.number} className="flex justify-center">
                <button
                  onClick={() => setCurrentHole(hole.number)}
                  className={`w-10 h-10 rounded-full flex items-center justify-center font-bold text-sm relative transition-all duration-200 
                    ${currentHole === hole.number ? 'bg-green-700 text-white shadow-md' : 'bg-gray-100 text-gray-700 hover:bg-gray-200 hover:shadow'} 
                    ${hole.activeGolfers && hole.activeGolfers.length > 0 ? 'ring-2 ring-yellow-400 transform hover:scale-105' : ''}
                  `}
                  title={`Hole ${hole.number} - Par ${hole.par}`}
                >
                  {hole.number}
                  {hole.activeGolfers && hole.activeGolfers.length > 0 && (
                    <span className="absolute -top-1 -right-1 bg-yellow-400 text-xs text-green-900 w-4 h-4 rounded-full flex items-center justify-center">
                      {hole.activeGolfers.length}
                    </span>
                  )}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Selected Hole Information */}
      {loading ? (
        <div className="text-center py-6 bg-white rounded-xl shadow-md">
          <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-green-700 mx-auto"></div>
          <p className="mt-2">Loading hole information...</p>
        </div>
      ) : error ? (
        <div className="text-center py-6 bg-white rounded-xl shadow-md">
          <svg className="w-12 h-12 mx-auto text-red-500 mb-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <p className="text-lg font-medium text-red-700">Error loading data</p>
          <p className="text-sm text-gray-600 mt-1">{error}</p>
          <p className="text-sm mt-3">Please try refreshing the page</p>
        </div>
      ) : (
        <div className="bg-white rounded-xl shadow-md overflow-hidden">
          {selectedHole ? (
            <>
              {/* Hole Header */}
              <div className="bg-gradient-to-r from-green-800 to-green-700 p-3 text-white flex justify-between items-center">
                <h2 className="text-lg font-bold">{selectedHole.name} (Hole {selectedHole.number})</h2>
                <div className="bg-white text-green-800 rounded-full px-3 py-1 text-sm font-bold shadow-inner">
                  Par {selectedHole.par}
                </div>
              </div>
              
              <div className="p-4">
                {/* Hole Description */}
                <div className="mb-4">
                  <p className="text-sm text-gray-700 bg-gray-50 p-3 rounded-lg border-l-4 border-green-700 mb-3">
                    {selectedHole.description}
                  </p>
                  
                  <div className="grid grid-cols-3 gap-2 text-center">
                    <div className="bg-white p-2 rounded shadow-sm">
                      <div className="text-xs text-gray-500">Par</div>
                      <div className="text-lg font-bold text-green-700">{selectedHole.par}</div>
                    </div>
                    <div className="bg-white p-2 rounded shadow-sm">
                      <div className="text-xs text-gray-500">Length</div>
                      <div className="text-lg font-bold text-green-700">{selectedHole.yards} <span className="text-xs font-normal">yds</span></div>
                    </div>
                    <div className="bg-white p-2 rounded shadow-sm">
                      <div className="text-xs text-gray-500">Active Golfers</div>
                      <div className="text-lg font-bold text-yellow-500">{selectedHole.activeGolfers?.length || 0}</div>
                    </div>
                  </div>
                </div>
                
                {/* Current Activity Section */}
                <div className="mt-4">
                  <h3 className="text-md font-bold text-green-800 mb-2">Current Activity</h3>

                  {selectedHole.activeGolfers && selectedHole.activeGolfers.length > 0 ? (
                    <div className="space-y-3">
                      {selectedHole.activeGolfers.map((golfer, index) => (
                        <div key={index} className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden transition-all hover:shadow-md">
                          {/* Golfer Header */}
                          <div className="p-3 bg-gray-50 border-b">
                            <div className="flex items-center">
                              {golfer.image ? (
                                <img 
                                  src={golfer.image} 
                                  alt={golfer.name}
                                  className="w-10 h-10 rounded-full object-cover mr-3 bg-gray-200"
                                  onError={(e) => {
                                    e.target.onerror = null; 
                                    e.target.src = ""; 
                                    e.target.className = "hidden"; 
                                  }}
                                />
                              ) : (
                                <span className="text-xl mr-3" title={golfer.country}>
                                  {getCountryFlag(golfer.country)}
                                </span>
                              )}
                              <div>
                                <div className="font-medium text-gray-800 flex items-center">
                                  {golfer.name}
                                  <span className="ml-2 text-xs inline-block px-2 py-0.5 bg-gray-100 rounded-full">
                                    {getCountryFlag(golfer.country)} {golfer.country}
                                  </span>
                                  {golfer.isBack9 && (
                                    <span className="ml-2 text-xs px-2 py-0.5 bg-blue-100 text-blue-800 rounded-full">
                                      Back 9
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            {/* Score and Points Info */}
                            <div className="flex flex-wrap items-center gap-2 mt-2">
                              <span className="bg-gray-100 rounded-md px-3 py-1 text-sm">
                                  Handicap: {golfer.handicap}
                              </span>
                              <div className="bg-gray-100 rounded-md px-3 py-1 text-sm">
                                <span className="font-medium">Strokes:</span> {golfer.score || '0'}{golfer.isBack9 ? '*' : ''}
                                <span className="mx-1">|</span>
                                {golfer.score ? (
                                  <span className="font-medium relative">
                                    {(() => {
                                      // Get par score directly from the data
                                      const parScore = golfer.parScore;
                                      
                                      // If parScore is not available, default to E
                                      if (!parScore) return <span>E</span>;
                                      
                                      // Parse to a number to determine display
                                      const parScoreValue = parseInt(parScore, 10) || 0;
                                      
                                      // Determine score shape based on the value
                                      if (parScoreValue < 0) {
                                        // Circle for under par
                                        return (
                                          <span className="inline-block relative">
                                            <span className="absolute inset-0 border border-black rounded-full" style={{width: '100%', height: '100%'}}></span>
                                            <span className="relative px-1 text-green-700 font-medium">{parScoreValue}</span>
                                          </span>
                                        );
                                      } else if (parScoreValue > 0) {
                                        // Square for over par
                                        return (
                                          <span className="inline-block relative">
                                            <span className="absolute inset-0 border border-black" style={{width: '100%', height: '100%'}}></span>
                                            <span className="relative px-1 text-red-700 font-medium">+{parScoreValue}</span>
                                          </span>
                                        );
                                      } else {
                                        // No shape for even
                                        return <span>E</span>;
                                      }
                                    })()}
                                  </span>
                                ) : (
                                  <span className="font-medium">Score: N/A</span>
                                )}
                              </div>
                              
                              {golfer.total > 0 && (
                                <div className="bg-green-100 text-green-800 rounded-md px-3 py-1 text-sm font-medium">
                                  Points: {golfer.total}
                                </div>
                              )}
                            </div>
                          </div>

                          {/* Teams */}
                          <div className="p-3">
                            <div className="text-sm text-gray-700 font-medium mb-1">Players:</div>
                            <div className="text-sm bg-gray-50 p-2 rounded max-h-24 overflow-y-auto">
                              {golfer.player && typeof golfer.player === 'string' ? (
                                golfer.player.split(', ').map((player, idx) => (
                                  <div key={idx} className="py-1 hover:bg-gray-100 px-2 rounded">
                                    {player}
                                  </div>
                                ))
                              ) : (
                                <div className="py-1 text-gray-500">No players available</div>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="bg-gray-50 rounded-lg p-6 text-center text-gray-500">
                      <p className="text-lg font-medium">No active golfers on this hole</p>
                      <p className="text-sm mt-1">This hole is currently not being played</p>
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <div className="p-6 text-center">
              <svg className="w-12 h-12 mx-auto text-gray-400 mb-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M12 13a1 1 0 110-2 1 1 0 010 2z" />
              </svg>
              <p className="text-xl font-medium text-gray-700">No hole information available</p>
              <p className="text-gray-500 mt-1">Please try refreshing the page</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CoursePage;