// Path: /src/components/RoundCard.js
// Updated to use constants for score thresholds and show par status message

import React from 'react';
import { getRoundStatus, isUnderPar } from './utils';
import { SCORE_THRESHOLDS } from './constants';

const RoundCard = ({ day, roundNumber, isCut, isBack9, isLegendary = false }) => {
  if (!day) return null;
  
  // Handle additional CSV-specific statuses
  let customStatus = null;
  
  // Check for withdrawal - but don't make it override score display
  const isWithdrawn = day.isWD || day.withdrawnAt;
  
  // Show CUT status for rounds 3 and 4 if the golfer is cut
  if (isCut && (roundNumber === 3 || roundNumber === 4)) {
    customStatus = {
      icon: '⊘',
      label: 'Cut',
      statusClass: 'cut',
      showScore: false,
      showHole: false,
      showTeeTime: false,
      isCut: true
    };
  } else if (day.status === 'FINISHED' || day.holeDisplay === 'F') {
    // Display 'F' for finished rounds (either by status or holeDisplay property)
    customStatus = {
      icon: '🏁',
      label: 'Finished',
      statusClass: 'completed',
      showScore: true,
      showHole: false,
      showTeeTime: false
    };
  } else if (day.hole > 0 && day.hole < 19) {
    // Use holeDisplay if available instead of hole for display
    customStatus = {
      icon: '⛳',
      label: `Thru ${day.holeDisplay || day.hole}`,  
      statusClass: 'inprogress',
      showScore: true,
      showHole: true,
      showTeeTime: false
    };
  } else if (day.teeTime) {
    customStatus = {
      icon: '🕒',
      label: `Tee time: ${day.teeTime}`,
      statusClass: 'waiting',
      showScore: false,
      showHole: false,
      showTeeTime: true
    };
  } else if (day.status === 'NEXT' || day.status === 'SCHEDULED') {
    customStatus = {
      icon: '⏭️',
      label: 'Next round',
      statusClass: 'next',
      showScore: false,
      showHole: false,
      showTeeTime: true  // Changed to true to show tee time for scheduled rounds
    };
  } else if (day.status === 'FUTURE') {
    customStatus = {
      icon: '⏳',
      label: 'Future round',
      statusClass: 'future',
      showScore: false,
      showHole: false,
      showTeeTime: false
    };
  }
  
  // Use custom status or fallback to the general function
  const status = customStatus || getRoundStatus(day);
  const hasPoints = day.points > 0;
  const isUnderParScore = isUnderPar(day.score);
  
  // Determine if this is the current round (has hole > 0 or status is ongoing)
  const isCurrentRound = day.hole > 0 || 
                        status.statusClass === 'inprogress';
                        
  // Check if the round is finished
  const isFinished = status.statusClass === 'completed' || day.holeDisplay === 'F';
  
  // Get class for points display based on point categories using the constants
  const getPointsClass = (points) => {
    if (points >= SCORE_THRESHOLDS.LEGENDARY) return "legendary-score"; // Legendary
    if (points >= SCORE_THRESHOLDS.EXCEPTIONAL) return "gold-shine bg-yellow-300 text-green-900 border-yellow-400"; // Exceptional
    if (points >= SCORE_THRESHOLDS.STRONG) return "bg-green-100 text-green-800 border-green-400";    // Strong
    if (points > 0) return "bg-blue-100 text-blue-800 border-blue-400";         // Points earned
    return "text-gray-500";                                                     // No points
  };
  
  // Parse the score to determine over/under par status
  const getParMessage = () => {
    if (!day.score || day.score === '') return '';
    
    // For par score, we need to use parScore directly
    if (day.parScore) {
      // parScore should already be relative to par (e.g., -3, 0, +2)
      const parScoreValue = parseInt(day.parScore, 10);
      
      if (!isNaN(parScoreValue)) {
        if (parScoreValue < 0) {
          // Under par (negative value means under par)
          return `Finished ${Math.abs(parScoreValue)} under par`;
        } else if (parScoreValue > 0) {
          // Over par
          return `Finished ${parScoreValue} over par`;
        } else {
          // Even par
          return `Finished even par`;
        }
      }
    }
    
    // If parScore isn't available, we can't determine over/under par status
    return '';
  };
  
  // Determine the base class for the round card
  // Adjust background for legendary golfers
  const roundCardClass = `rounded-lg p-3 ${
    hasPoints ? (isLegendary ? 'bg-transparent' : 'bg-green-50') : 
    (isLegendary ? 'bg-transparent' : 'bg-gray-50')
  } hover:shadow-md transition-all h-full flex flex-col round-card`;
  
  return (
    <div className={roundCardClass}>
      <div className="flex justify-between items-center mb-2">
        <div className="font-medium text-sm flex items-center">
          R{roundNumber} 
          {/* Show bandage icon for withdrawn rounds */}
          {isWithdrawn ? (
            <span className="ml-1 text-gray-500" title="Withdrawn">🩹</span>
          ) : (
            <span className={`ml-1 ${
              status.statusClass === 'completed' ? 'text-green-700' :
              status.statusClass === 'inprogress' ? 'text-yellow-500' :
              status.statusClass === 'waiting' ? 'text-gray-500' :
              status.statusClass === 'cut' ? 'text-red-500' :
              status.statusClass === 'next' ? 'text-blue-500' :
              status.statusClass === 'future' ? 'text-gray-400' : ''
            }`}>
              {status.icon}
            </span>
          )}
        </div>
        <div>
          {hasPoints ? (
            <div className="relative" style={{zIndex: 5, display: 'inline-block'}}>
              <span className={`font-bold text-sm px-2 py-1 rounded-full border ${getPointsClass(day.points)}`}>
                {day.points}
              </span>
            </div>
          ) : (
            <span className="font-bold text-sm text-gray-500">
              {day.points}
            </span>
          )}
        </div>
      </div>
      
      <div className="flex justify-center items-center flex-grow min-h-8">
        {/* Display tee time */}
        {status.showTeeTime && day.teeTime && !isWithdrawn && (
          <span className="text-gray-500 text-sm">{day.teeTime}</span>
        )}
        
        {(status.isCut || day.score === "CUT" || day.status === "CUT") && !isWithdrawn && (
          <span className="text-red-500 font-bold">CUT</span>
        )}
        
        {/* Always show score for withdrawn players */}
        {isWithdrawn && day.score && (
          <div className="relative flex items-center justify-center">
            {isUnderParScore && (
              <div className="absolute w-6 h-6 border-2 border-green-700 rounded-full"></div>
            )}
            <span className="font-semibold z-10">
              {day.score}&nbsp;
            </span>
          </div>
        )}
        
        {/* Show score for normal rounds */}
        {status.showScore && !(day.score === "CUT" || day.status === "CUT") && !status.isCut && !isWithdrawn && (
          <div className="relative flex items-center justify-center">
            {isUnderParScore && (
              <div className="absolute w-6 h-6 border-2 border-green-700 rounded-full"></div>
            )}
            <span className="font-semibold z-10">
              {day.score}&nbsp;
            </span>
          </div>
        )}
        
        {status.showHole && day.hole > 0 && day.hole < 19 && !isWithdrawn && (
          <div className="flex items-center justify-center">
            <span className="text-yellow-500 text-xs font-medium">
              {day.holeDisplay === 'F' ? 'F' : `Thru ${day.holeDisplay || day.hole}`}
              {isBack9 ? '*' : ''}
            </span>
          </div>
        )}
      </div>
      
      {/* Show subtle withdrawal message */}
      {isWithdrawn && (
        <div className="mt-1 text-xs text-gray-500 text-center">
          Withdrawn
        </div>
      )}
      
      {/* Show finished par status message */}
      {!isWithdrawn && isFinished && day.score ? (
        <div className="mt-1 text-xs text-center">
          <span className={isUnderParScore ? "text-green-600 font-medium" : "text-gray-600"}>
            {getParMessage()}
          </span>
        </div>
      ) : 
      /* Par for day row - only show for current round and not finished rounds */
      !isWithdrawn && day.parForDay && day.parForDay !== "null" && day.parForDay !== "" && isCurrentRound && !isFinished ? (
        <div className="mt-1 text-xs text-gray-600 text-center">
          Par for day: {day.parForDay}
        </div>
      ) : (
        !isWithdrawn && (
          <div className="mt-1 text-xs text-gray-600 text-center min-h-4">
            &nbsp;
          </div>
        )
      )}
    </div>
  );
};

export default RoundCard;