// Path: /src/index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import EnhancedLeaderboard from './components/EnhancedLeaderboard';

// Wait for DOM to be fully loaded
document.addEventListener('DOMContentLoaded', () => {
  // Get the root element
  const container = document.getElementById('root');
  
  // Create a React root
  const root = createRoot(container);
  
  // Render the React application
  root.render(
    <React.StrictMode>
      <EnhancedLeaderboard />
    </React.StrictMode>
  );
  
  console.log('React application loaded successfully');
});