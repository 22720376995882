// Path: /src/components/utils.js
// Consolidated utility functions for the Masters Golf Sweepstakes app

export const SCORE_THRESHOLDS = {
  LEGENDARY: 350,  // Threshold for legendary score (gold card + rainbow badge)
  EXCEPTIONAL: 120, // Threshold for exceptional score (gold shine badge)
  STRONG: 50,      // Threshold for strong score (green badge)
};

// Country code mapping for flags
export const countryFlags = {
  'USA': '🇺🇸',
  'UK': '🇬🇧',
  'England': '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
  'Scotland': '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
  'Wales': '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
  'Ireland': '🇮🇪',
  'Northern Ireland': '🇬🇧 🇮🇪',
  'Spain': '🇪🇸',
  'Australia': '🇦🇺',
  'Canada': '🇨🇦',
  'Japan': '🇯🇵',
  'South Korea': '🇰🇷',
  'Korea': '🇰🇷',
  'Germany': '🇩🇪',
  'South Africa': '🇿🇦',
  'Sweden': '🇸🇪',
  'Norway': '🇳🇴',
  'Denmark': '🇩🇰',
  'Finland': '🇫🇮',
  'Italy': '🇮🇹',
  'France': '🇫🇷',
  'Mexico': '🇲🇽',
  'China': '🇨🇳',
  'Argentina': '🇦🇷',
  'Chile': '🇨🇱',
  'Colombia': '🇨🇴',
  'Thailand': '🇹🇭',
  'Taiwan': '🇹🇼',
  'India': '🇮🇳',
  'New Zealand': '🇳🇿',
  'Belgium': '🇧🇪',
  'Netherlands': '🇳🇱',
  'Portugal': '🇵🇹',
  'Austria': '🇦🇹',
  'Switzerland': '🇨🇭',
  'Brazil': '🇧🇷',
  'Philippines': '🇵🇭',
  'Malaysia': '🇲🇾',
  'Singapore': '🇸🇬',
  'Indonesia': '🇮🇩',
  'Vietnam': '🇻🇳',
  'Poland': '🇵🇱',
  'Russia': '🇷🇺',
  'Ukraine': '🇺🇦',
  'Greece': '🇬🇷',
  'Turkey': '🇹🇷',
  'Saudi Arabia': '🇸🇦',
  'United Arab Emirates': '🇦🇪',
  'UAE': '🇦🇪',
  'Qatar': '🇶🇦',
  'Bahrain': '🇧🇭',
  'Kuwait': '🇰🇼',
  'Oman': '🇴🇲',
  'Pakistan': '🇵🇰',
  'Bangladesh': '🇧🇩',
  'Sri Lanka': '🇱🇰',
  'Nepal': '🇳🇵',
  'Zimbabwe': '🇿🇼',
  'Kenya': '🇰🇪',
  'Nigeria': '🇳🇬',
  'Ghana': '🇬🇭',
  'Egypt': '🇪🇬',
  'Morocco': '🇲🇦',
  'Tunisia': '🇹🇳',
  'Algeria': '🇩🇿',
  'Czech Republic': '🇨🇿',
  'Czechia': '🇨🇿',
  'Slovakia': '🇸🇰',
  'Hungary': '🇭🇺',
  'Romania': '🇷🇴',
  'Bulgaria': '🇧🇬',
  'Slovenia': '🇸🇮',
  'Croatia': '🇭🇷',
  'Serbia': '🇷🇸',
  'Bosnia and Herzegovina': '🇧🇦',
  'Bosnia': '🇧🇦',
  'Montenegro': '🇲🇪',
  'North Macedonia': '🇲🇰',
  'Macedonia': '🇲🇰',
  'Albania': '🇦🇱',
  'Kosovo': '🇽🇰',
  'Estonia': '🇪🇪',
  'Latvia': '🇱🇻',
  'Lithuania': '🇱🇹',
  'Belarus': '🇧🇾',
  'Moldova': '🇲🇩',
  'Iceland': '🇮🇸',
  'Luxembourg': '🇱🇺',
  'Malta': '🇲🇹',
  'Cyprus': '🇨🇾',
  'Paraguay': '🇵🇾',
  'Uruguay': '🇺🇾',
  'Bolivia': '🇧🇴',
  'Ecuador': '🇪🇨',
  'Peru': '🇵🇪',
  'Venezuela': '🇻🇪',
  'Costa Rica': '🇨🇷',
  'Panama': '🇵🇦',
  'Guatemala': '🇬🇹',
  'Honduras': '🇭🇳',
  'El Salvador': '🇸🇻',
  'Nicaragua': '🇳🇮',
  'Jamaica': '🇯🇲',
  'Trinidad and Tobago': '🇹🇹',
  'Trinidad': '🇹🇹',
  'Barbados': '🇧🇧',
  'Bahamas': '🇧🇸',
  'Dominican Republic': '🇩🇴',
  'Puerto Rico': '🇵🇷',
  'Cuba': '🇨🇺',
  'Haiti': '🇭🇹',
  'Fiji': '🇫🇯',
  'Samoa': '🇼🇸',
  'Tonga': '🇹🇴',
  'Papua New Guinea': '🇵🇬',
  'PNG': '🇵🇬',
  'Mongolia': '🇲🇳',
  'Kazakhstan': '🇰🇿',
  'Uzbekistan': '🇺🇿',
  'Turkmenistan': '🇹🇲',
  'Kyrgyzstan': '🇰🇬',
  'Tajikistan': '🇹🇯',
  'Afghanistan': '🇦🇫',
  'Iran': '🇮🇷',
  'Iraq': '🇮🇶',
  'Jordan': '🇯🇴',
  'Lebanon': '🇱🇧',
  'Syria': '🇸🇾',
  'Israel': '🇮🇱',
  'Palestine': '🇵🇸',
  'Cambodia': '🇰🇭',
  'Laos': '🇱🇦',
  'Myanmar': '🇲🇲',
  'Burma': '🇲🇲',
  'Hong Kong': '🇭🇰',
  'Macau': '🇲🇴',
  'Default': '🏌️'
};

// Format date for display
export const formatDate = (date) => {
  return new Date(date).toLocaleString();
};

// Get CSS class for points badge
export const getPointsClass = (points) => {
  if (points >= 120) return "points-exceptional";
  if (points >= 50) return "points-strong";
  if (points > 0) return "points-earned";
  return "points-none";
};

// Get status icons and info for a round
export const getRoundStatus = (day) => {
  if (!day) return { icon: '', label: '', statusClass: '' };
  
  // Handle specific status values from CSV
  if (day.score === "CUT" || day.status === "CUT") {
    return { 
      icon: '⊘', 
      label: 'Cut', 
      statusClass: 'cut',
      showScore: false,
      showHole: false,
      showTeeTime: false
    };
  }
  
  // Handle the specific statuses from your CSV
  switch (day.status) {
    case 'FINISHED':
      return { 
        icon: '🏁', 
        label: 'Finished', 
        statusClass: 'completed',
        showScore: true,
        showHole: false,
        showTeeTime: false
      };
      
    case 'CURRENT':
      // Check if the player has finished the round (holeDisplay = 'F')
      if (day.holeDisplay === 'F') {
        return { 
          icon: '🏁', 
          label: 'Finished round', 
          statusClass: 'completed',
          showScore: true,
          showHole: false,
          showTeeTime: false
        };
      }
      
      return { 
        icon: '⛳', 
        label: `In Progress${day.hole ? ` (Hole ${day.hole})` : ''}`, 
        statusClass: 'inprogress',
        showScore: true,
        showHole: true,
        showTeeTime: false
      };
      
    case 'NEXT':
      return { 
        icon: '⏭️', 
        label: 'Next round', 
        statusClass: 'next',
        showScore: false,
        showHole: false,
        showTeeTime: true
      };
      
    case 'FUTURE':
      return { 
        icon: '⏳', 
        label: 'Future round', 
        statusClass: 'future',
        showScore: false,
        showHole: false,
        showTeeTime: false
      };
      
    case 'WAITING':
      return { 
        icon: '⏱', 
        label: 'Waiting to Start', 
        statusClass: 'waiting',
        showScore: false,
        showHole: false,
        showTeeTime: true
      };
  }
  
  // Fallback to hole-based logic if status not explicitly provided
  if (day.teeTime) {
    return { 
      icon: '⏱', 
      label: `Tee time: ${day.teeTime}`, 
      statusClass: 'waiting',
      showScore: false,
      showHole: false,
      showTeeTime: true
    };
  } else if (day.hole === 0) {
    return { 
      icon: '⏳', 
      label: 'Not started', 
      statusClass: 'waiting',
      showScore: false,
      showHole: false,
      showTeeTime: false
    };
  } else if (day.hole > 0) {
    return { 
      icon: '⛳', 
      label: `Hole ${day.hole}`, 
      statusClass: 'inprogress',
      showScore: true,
      showHole: true,
      showTeeTime: false
    };
  }
  
  // Default fallback
  return { 
    icon: '❓', 
    label: 'Unknown', 
    statusClass: '',
    showScore: false,
    showHole: false,
    showTeeTime: false
  };
};

// Check if score is under par
export const isUnderPar = (score) => {
  // Handle string scores with "-" prefix indicating under par
  if (typeof score === 'string') {
    // Some scores might be numeric without a sign, so we need to convert them
    if (score.startsWith('-')) return true;
    
    // Try to handle numeric scores as strings
    const numericScore = parseInt(score, 10);
    return !isNaN(numericScore) && numericScore < 0;
  }
  
  // Handle numeric scores directly
  if (typeof score === 'number') {
    return score < 0;
  }
  
  // Default is not under par
  return false;
};

// Parse a golf score from CSV format
export const parseGolfScore = (score) => {
  // Return as-is if it's already a formatted string with sign
  if (typeof score === 'string' && (score.startsWith('-') || score.startsWith('+'))) {
    return score;
  }
  
  // Handle numeric scores
  let numericScore = typeof score === 'number' ? score : parseInt(score, 10);
  
  // If it's a valid number, format it with sign
  if (!isNaN(numericScore)) {
    return numericScore < 0 ? `${numericScore}` : 
           numericScore > 0 ? `+${numericScore}` : 
           'E'; // E for Even (0)
  }
  
  // For special scores like "CUT" or empty strings
  return score || '';
};

// Get country flag emoji
export const getCountryFlag = (country) => {
  if (!country) return countryFlags['Default'];
  return countryFlags[country] || countryFlags['Default'];
};

// No additional processing needed as the server already provides the correct format
export const processGolferData = (rawData) => {
  return rawData;
};